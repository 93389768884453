import React, {type FC} from 'react';
import {View, useWindowDimensions} from 'react-native';

import HomeListHeader from './HomeListHeader';

type ItemProps = {
  actualWidth: number;
  width: number;
};

const PopularLoader: FC<ItemProps> = props => {
  const {actualWidth, width} = props;
  const itemWidth =
    (actualWidth - (width <= 768 ? 16 * 3 : width >= 1080 ? 16 * 2 : 16 * 4)) /
    (width <= 768 ? 2 : 3);

  const actItemWidth = actualWidth <= 460 ? actualWidth * 0.8 : itemWidth;
  const actHeight = (actItemWidth * 2.5) / 4;

  const isMore = actualWidth >= 1080;
  const isLessEqual = width <= 1080;
  const isLess = actualWidth < 1080;

  return (
    <View className="flex py-2 max-w-custom self-stretch gap-6 custom:self-center mt-4">
      <View className="mx-6 custom:mx-0 w-[40%] h-[18px] custom:h-[28px] rounded-[2px] bg-lt-background" />

      <View
        style={{height: actHeight}}
        className="w-full max-w-[1080px] self-stretch flex-row gap-[16px]">
        {[1, 2, 3].map((i, index) => (
          <View
            key={index.toString()}
            className="bg-lt-background"
            style={{
              aspectRatio: 4 / 2.5,
              width: actItemWidth,
              marginLeft: isMore ? undefined : 16,
              marginRight: isLessEqual && index == 2 ? 16 : undefined,
              maxWidth: isLess ? undefined : itemWidth,
            }}
          />
        ))}
      </View>
    </View>
  );
};

const HomeLoader = () => {
  const {width} = useWindowDimensions();
  const actualWidth = width > 1080 ? 1080 : width;
  return (
    <View className="flex flex-1">
      <HomeListHeader
        onPressImage={() => {}}
        isLoading
        onPressLogin={() => {}}
        type="tour"
        onPressType={() => {}}
        onPressSearch={() => {}}
      />
      <PopularLoader {...{actualWidth, width}} />
      <PopularLoader {...{actualWidth, width}} />
    </View>
  );
};

export default HomeLoader;
