import {createIconSetFromIcoMoon} from 'react-native-vector-icons';

import icoMoonConfig from './selection.json';
import {remapProps} from 'nativewind';

export enum IconTypes {
  Eye = 'eye',
  ArrowBack = 'arrow-back',
  ChevronRight = 'chevron-right',
  ChevronLeft = 'chevron-left',
  Bell = 'bell',
  ArrowRightCircle = 'arrow-right-circle',
  Home = 'home',
  Search = 'search',
  User = 'user',
  Tent = 'tent',
  Car = 'car',
  Call = 'call',
  Cancel = 'cancel',
  Image = 'image',
  Select = 'select',
  Location = 'location',
  Plus = 'plus',
  Minus = 'minus',
  More = 'more',
  Hike = 'hike',
  Users = 'users',
  Paper = 'paper',
  Mute = 'sound-mute',
  UnMute = 'sound-max',
  Play = 'play',
  Pause = 'pause',
  Calendar = 'calendar',
  Clock = 'clock',
}

const Icon = createIconSetFromIcoMoon(icoMoonConfig);

remapProps(Icon, {className: 'style'});

export default Icon;
