import React, {Suspense} from 'react';
import {ActivityIndicator} from 'react-native';

const withLazy = (
  Comp: any,
  fallback: any = <ActivityIndicator size="small" />,
) => {
  return (props: any) => {
    return (
      <Suspense fallback={fallback}>
        <Comp {...props} />
      </Suspense>
    );
  };
};

export default withLazy;
