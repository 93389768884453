import type {BottomTabScreenProps} from '@react-navigation/bottom-tabs';
import type {StackScreenProps} from '@react-navigation/stack';
import type {IHomeStateType} from './tour';

export type HomeTabScreenParams =
  | {
      type?: IHomeStateType;
    }
  | undefined;

export type ProfileTabScreenParams = {} | undefined;

type TourDetailsParams = {
  id: string;
};

type ActivityDetailsParams = {
  id: string;
};

export enum TabScreens {
  Home = 'home',

  Destinations = 'Destinations',
  Profile = 'Profile',
  Blogs = 'blogs',
}

export enum MainScreens {
  Tabs = 'tabs',
  TourDetails = 'tours',
  Profile = 'profile',
  About = 'About',
  Terms = 'Terms',
  ActivityDetails = 'activities',
  PrivacyPolicies = 'PrivacyPolicies',
  Support = 'Support',
  NotFound = 'NotFound',
  AttractionDetails = 'AttractionDetails',
  Reviews = 'Reviews',
  MyBookings = 'MyBookings',
  OrderDetails = 'OrderDetails',
  Destinations = 'Destinations',
  DestinationDetails = 'DestinationDetails',
  Contact = 'Contact',
  Hiring = 'Hiring',
  Blogs = 'Blogs',
  BlogDetails = 'BlogDetails',
  Search = 'Search',
  Reels = 'reels',
  GroupTours = 'group-tours',
  Affiliation = 'Affiliation',
  GroupTourDetails = 'group-tour-details',
  Careers = 'careers',
  CareerDetails = 'career-details',
}

export type TabParamList = {
  [TabScreens.Home]: HomeTabScreenParams;
  [TabScreens.Destinations]: {};

  [TabScreens.Blogs]: {};
  reels: {};
  [TabScreens.Profile]: ProfileTabScreenParams;
};

export type TabParamListNative = {
  [TabScreens.Home]: HomeTabScreenParams;
  [TabScreens.Destinations]: {};

  [TabScreens.Blogs]: {};
  reels: {};
  [TabScreens.Profile]: ProfileTabScreenParams;
};

export type MainStackParamList = {
  [MainScreens.Tabs]: {initialRouteName?: TabScreens} | undefined;
  [MainScreens.TourDetails]: TourDetailsParams;
  [MainScreens.About]: {} | undefined;
  [MainScreens.Terms]: {} | undefined;
  [MainScreens.PrivacyPolicies]: {} | undefined;
  [MainScreens.Support]: {} | undefined;
  [MainScreens.NotFound]: {} | undefined;
  [MainScreens.AttractionDetails]: {id: string};
  [MainScreens.Reviews]: {} | undefined;
  [MainScreens.ActivityDetails]: ActivityDetailsParams;
  [MainScreens.MyBookings]: {} | undefined;
  [MainScreens.OrderDetails]: {id: string};
  [MainScreens.Destinations]: {} | undefined;
  [MainScreens.Profile]: {} | undefined;
  [MainScreens.DestinationDetails]: {
    id: string;
    type?: 'tour' | 'activity' | 'group-tour';
  };
  [MainScreens.Affiliation]: {};
  [MainScreens.Search]: {
    item?: 'tour' | 'activity';
    city: string; // the city of the Destination
    price_min: number; // the minimum price to search
    price_max: number; // the maximum price to search
    duration_in: 'days' | 'hours' | 'minutes'; // default days
    duration: number;
  };
  [MainScreens.Hiring]: undefined;
  [MainScreens.Contact]: undefined;
  [MainScreens.Blogs]: undefined;
  [MainScreens.BlogDetails]: {id: string};
  [MainScreens.Reels]: {};
  [MainScreens.GroupTours]: {
    type: 'international' | 'domestic';
  };
  [MainScreens.GroupTourDetails]: {
    id: string;
  };
  [MainScreens.Careers]: {};
  [MainScreens.CareerDetails]: {id: string};
};

export type TSearchScreenParams = {
  item?: 'tour' | 'activity';
  city: string; // the city of the Destination
  price_min: number; // the minimum price to search
  price_max: number; // the maximum price to search
  duration_in: 'days' | 'hours' | 'minutes'; // default days
  duration?: number;
  duration_max?: number;
  min_duration?: number;
};
export type TourDetailsScreenParams = StackScreenProps<
  MainStackParamList,
  MainScreens.TourDetails
>;

export type TabsScreenParams = StackScreenProps<
  MainStackParamList,
  MainScreens.Tabs
>;

export type ActivityDetailsScreenParams = StackScreenProps<
  MainStackParamList,
  MainScreens.ActivityDetails
>;

export type AboutScreenParams = StackScreenProps<
  MainStackParamList,
  MainScreens.About
>;

export type TermsScreenParams = StackScreenProps<
  MainStackParamList,
  MainScreens.Terms
>;

export type PrivacyPoliciesScreenParams = StackScreenProps<
  MainStackParamList,
  MainScreens.PrivacyPolicies
>;

export type SupportScreenParams = StackScreenProps<
  MainStackParamList,
  MainScreens.Support
>;

export type AttractionDetailsScreenParams = StackScreenProps<
  MainStackParamList,
  MainScreens.AttractionDetails
>;

export type ReviewsScreenParams = StackScreenProps<
  MainStackParamList,
  MainScreens.Reviews
>;

export type MyBookingsScreenParams = StackScreenProps<
  MainStackParamList,
  MainScreens.MyBookings
>;

export type OrderDetailsScreenParams = StackScreenProps<
  MainStackParamList,
  MainScreens.OrderDetails
>;

export type ProfileScreenParams = BottomTabScreenProps<
  TabParamList,
  TabScreens.Profile
>;
export type TAffiliationScreenProps = StackScreenProps<
  MainStackParamList,
  MainScreens.Affiliation
>;

export type DestinationsScreenParams = StackScreenProps<
  MainStackParamList,
  MainScreens.Destinations
>;

export type DestinationDetailsScreenParams = StackScreenProps<
  MainStackParamList,
  MainScreens.DestinationDetails
>;

export type BlogsScreenParams = StackScreenProps<
  MainStackParamList,
  MainScreens.Blogs
>;

export type BlogDetailsScreenParams = StackScreenProps<
  MainStackParamList,
  MainScreens.BlogDetails
>;

export type ISearchScreenProps = StackScreenProps<
  MainStackParamList,
  MainScreens.Search
>;

export type TReelsScreenProps = StackScreenProps<
  MainStackParamList,
  MainScreens.Reels
>;

export type TGroupToursScreenProps = StackScreenProps<
  MainStackParamList,
  MainScreens.GroupTours
>;

export type TGroupTourDetailsScreenProps = StackScreenProps<
  MainStackParamList,
  MainScreens.GroupTourDetails
>;

export type TCareersScreenProps = StackScreenProps<
  MainStackParamList,
  MainScreens.Careers
>;
export type TCareerDetailsScreenProps = StackScreenProps<
  MainStackParamList,
  MainScreens.CareerDetails
>;
